<template>
    <onboarding-layout
        :title="$t('pages.shared.offerAddCoApplicant.header')"
        :loading="loading"
    >
        <div class="d-grid">
            <form-button
                button-classes="p-2"
                data-testid="offer-add-coapplicant-continue-button"
                @click="onContinue"
                :submitting="submitting"
                type="submit"
                :block="true"
                :label="$t('pages.shared.offerAddCoApplicant.cta')"
            />
        </div>
        <a
            href="#"
            @click="getDocument(LegalDocumentTypes.adverseActionNotice)"
            class="btn btn-link text-decoration-none text-primary fw-bold pt-0 mt-3"
        >No Thank You</a>

        <template #sidebar>
            <ul class="list-unstyled list-row">
                <joint-list-item />
                <help-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import FormButton from '@/components/base/FormButton'
    import { misc } from '@/mixins/misc'
    import JointListItem from '@/components/onboarding/JointListItem'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import { getNextRoute } from '@/flow/flowController'
    import { getDocumentForApplicantAndOpen } from '@/utils/document'
    import { logger } from '@/utils/logger'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { i18n } from '@/utils/i18n'
    import { LegalDocumentTypes } from '@/services/api'
    import authMixin from '@/mixins/authMixin'
    import personalInfoMixin from '../../mixins/personalInfoMixin'
    import { httpClient } from '../../utils/http-client'
    import { postUpdateApplicantFields } from '../../services/api'
    import { appSessionStorage } from '../../utils/storage'

    export default {
        components: {
            'onboarding-layout': OnboardingLayout,
            'form-button': FormButton,
            'joint-list-item': JointListItem,
            'help-list-item': HelpListItem,
        },
        mixins: [misc, authMixin, personalInfoMixin],
        data: function () {
            return {
                loading: false, // needed for assert in authMixin
                LegalDocumentTypes,
                submitting: false,
            }
        },
        mounted: function () {
            this.$logEvent('view_offer_add_co_applicant_on_failure')
        },
        methods: {
            onContinue: async function () {
                if (this.submitting) {
                    return
                }
                this.submitting = true

                // clear the session storage so we can start anew, keep info needed for context
                appSessionStorage.clearWithException(['currentFlow', 'loanApplicationId', 'phoneNumber', 'statedIncome'])

                // now launch a new session because we can't start a new loan app on the same session
                const resp = await httpClient.post('/ana/session', {} /* payload */)
                if (resp.data.error) {
                    await this.handleAuthErrors(resp)
                }

                logger.log('Setting sessionId: ' + resp.data.payload.sessionId)
                appSessionStorage.setItem('sessionAccessJWT', resp.data.payload.jwt.sessionAccessJWT)
                appSessionStorage.setItem('sessionId', resp.data.payload.sessionId)
                appSessionStorage.setItem('experimentName', resp.data.payload.experimentName)
                // ok now we're going to create a loan app using this session id and copy the contents of applicant and loan application over via endpt
                await this.setupAuth()

                // we do not have the applicant's basic info including address right now. we're going to get it from the applicant table
                // and then we're going to hit the update applicant fields endpoint to create home row
                const address = await httpClient.get('/getApplicantAddress', {})
                await postUpdateApplicantFields(address.data.payload, false /* is coApplicant */)

                // ok now move fwd
                this.submitting = false
                return this.$router.push(getNextRoute(this.$router))
            },
            getDocument: async function (docType) {
                try {
                    this.loadingTitle = 'Loading Document...'
                    this.loading = true
                    this.$logEvent('click_link_get_document', { docType })
                    await getDocumentForApplicantAndOpen(docType)
                } catch (e) {
                    logger.error(`failed to open pdfs document`, null /* event */, e)
                    this.errorText = ApiErrorHandler(e)
                }
                this.loading = false
                this.loadingTitle = i18n.t('offerPreview.loading')
            },
        },
    }
</script>
